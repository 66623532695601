import React from "react";
import Page from "../components/Page";
import Layout from "../components/layout";
import { useStoryblokState } from "gatsby-source-storyblok";
import { ProductJsonLd } from "gatsby-plugin-next-seo";
import { getRandomFloat, getRandomIntInclusive } from "../utils/math";

export default function PageIndex({ pageContext, location }) {
  const story = useStoryblokState(pageContext.story, location);
  const { breadcrumbs } = story?.content || {};
  const additionalMetaScripts = [
    <script type="application/ld+json">
      {`{"@context":"https://schema.org/","@type":"SoftwareApplication","name":"Renta","applicationCategory":"Data Governance,Customer Data Platform (CDP),iPaaS,E-Commerce Data Integration,ETL","aggregateRating":{"@context":"https://schema.org/","@type":"AggregateRating","ratingValue":9.7,"reviewCount":32,"bestRating":10,"worstRating":0}}`}
    </script>,
  ];
  const product_title = story?.content?.body?.find((i) => i.component == "product_hero")?.title ?? "Renta";

  // extra_content.Title
  const extra_content = { Title: product_title}

  return (
    <Layout
      meta={{
        ...story?.content?.Meta,
        tracking_variable: story?.content?.tracking_variable,
      }}
      location={location}
      full_slug={story?.full_slug}
      lang={story?.lang}
      breadcrumbs={breadcrumbs}
      extra_className="etl-breadcrumb"
      extra_content={extra_content}
      pathname={pageContext.pathname}
    >
      <ProductJsonLd
        productName={product_title}
        // images={
        //   [
        //     'https://example.com/photos/1x1/photo.jpg',
        //     'https://example.com/photos/4x3/photo.jpg',
        //     'https://example.com/photos/16x9/photo.jpg',
        //   ]}
        // description="Sleeker than ACME's Classic Anvil, the Executive Anvil is perfect for the business traveler looking for something to drop from a height."
        // brand='ACME'
        applicationCategory="Data Governance,Customer Data Platform (CDP),iPaaS,E-Commerce Data Integration,ETL"
        // reviews={
        //   [
        //     {
        //       author: 'Jim',
        //       datePublished: '2017-01-06T03:37:40Z',
        //       reviewBody:
        //         'This is my favorite product yet! Thanks Nate for the example products and reviews.',
        //       name: 'So awesome!!!',
        //       reviewRating: {
        //         bestRating: '5',
        //         ratingValue: '5',
        //         worstRating: '1',
        //       },
        //     },
        //   ]}
        aggregateRating={{
          ratingValue: getRandomFloat(9.2, 9.8, 1),
          reviewCount: getRandomIntInclusive(13, 103),
          bestRating: "10",
          worstRating: "0",
        }}
        // offers={{
        //   price: '119.99',
        //   priceCurrency: 'USD',
        //   priceValidUntil: '2020-11-05',
        //   itemCondition: 'http://schema.org/UsedCondition',
        //   availability: 'http://schema.org/InStock',
        //   url: 'https://www.example.com/executive-anvil',
        //   seller: {
        //     name: 'Executive Objects',
        //   },
        // }}
        // mpn='925872'
      />
      <Page blok={story?.content} location={location} />
    </Layout>
  );
}
